import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaUserTie,
  FaSearch,
  FaSignInAlt,
  FaSignOutAlt,
  FaUserPlus,
  FaUser,
  FaBell,
  FaTasks,
} from "react-icons/fa"; // Import icons
import AuthContext from "../AuthContext";
import logo from "../images/logo.png";
import axiosInstance from "../axiosInstance";

const Navbar = () => {
  const { isLoggedIn, logout, loggedUser,profileImageUrl } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false); 
  const [showNotificationNumbers, setShowNotificationNumbers] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        if (isLoggedIn) {
          const response = await axiosInstance.get("/api/notifications");
          setNotifications(response.data);
          const unreadNotifications = response.data.filter(
            (notification) => !notification.isRead
          );
          setNotificationCount(unreadNotifications.length); 
        }
      } catch (error) {
        console.error("Error fetching notifications", error);
      }
    };
    fetchNotifications();

    const intervalId = setInterval(fetchNotifications, 150000); // Fetch every 2 minutes

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [isLoggedIn]); 

  const handleMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };
  const toggleNotifications = () => {
    setShowNotificationNumbers(false);
    setNotificationsVisible(!notificationsVisible);

    axiosInstance.get("/api/markAllNotificationsRead");
  };
  const handleLogout = () => {
    logout();
    navigate("/");
  };


  return (
    <nav className="bg-gray-800">
      <div className="  mx-auto flex justify-between items-center ">
        {/* Logo or title */}
        <div>
          <Link
            to="/"
            className="flex items-center text-white text-lg font-bold"
          >
            <img
              src={logo}
              alt="logo"
              className="h-12 w-12 md:h-20 md:w-20 inline-block mr-2"
            />
            <span className="  md:inline">Skilled Mates</span>
          </Link>
        </div>

        {/* Menu Button for Mobile */}
        <div className="lg:hidden  flex items-center mr-3">
          {isLoggedIn && (
            <div className="relative mr-4">
              <button
                onClick={toggleNotifications}
                className="text-yellow-400 font-bold rounded-full w-10 h-10 flex items-center justify-center bg-gray-600 relative"
              >
                <FaBell size={20} />
                {showNotificationNumbers && notificationCount > 0 && (
                  <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                    {
                      notifications.filter(
                        (notification) => !notification.isRead
                      ).length
                    }
                  </span>
                )}
              </button>

              {notificationsVisible && (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-50">
                  {/* List of notifications */}
                  <div className="max-h-72 overflow-y-auto">
                    {notifications?.length === 0 ? (
                      <p className="px-4 py-2 text-gray-500">
                        No new notifications
                      </p>
                    ) : (
                      notifications?.map((notification) => (
                        <div
                          key={notification._id}
                          className={`px-4 py-2 bg-gray-100 border-b`}
                        >
                          <p className="text-sm text-gray-800">
                            {notification.message}
                          </p>
                          {notification.actionURL && (
                            <a
                              href={notification.actionURL}
                              className="text-blue-500 text-xs hover:underline"
                            >
                              Go to Task
                            </a>
                          )}

                          <p className="text-xs text-gray-500 mt-1">
                            {new Date(notification.createdOn).toLocaleString()}
                          </p>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          <button
            onClick={handleMenuToggle}
            className="text-white focus:outline-none"
            id="menu-button"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>

        {/* Navbar Links - Visible on larger screens, hidden on small screens */}
        <div className="hidden lg:flex lg:space-x-4 marker:flex items-center gap-2">
          <Link to="/tasks">
            <button className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2">
              <FaTasks size={20} />
              Find Tasks <span className="text-xs text-red-600 mt-1">New</span>
            </button>
          </Link>
          <Link to="/find-worker">
            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2">
              <FaUserTie size={20} />
              Hire a Worker
            </button>
          </Link>

          <Link to="/find-work">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2">
              <FaSearch size={20} />
              Find Work
            </button>
          </Link>
          {isLoggedIn && (
            <div className="relative">
              <button
                onClick={toggleNotifications}
                className="bg-gray-500 text-yellow-400 font-bold rounded-full w-12 h-12 flex items-center justify-center relative"
              >
                <FaBell size={20} />
                {/* Notification badge with the number of unread notifications */}
                {showNotificationNumbers && notificationCount > 0 && (
                  <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                    {notificationCount}
                  </span>
                )}
              </button>

              {notificationsVisible && (
                <div className="absolute right-0 mt-2 w-64 bg-white border rounded shadow-lg z-50">
                  {/* List of notifications */}
                  <div className="max-h-72 overflow-y-auto">
                    {notifications?.length === 0 ? (
                      <p className="px-4 py-2 text-gray-500">
                        No new notifications
                      </p>
                    ) : (
                      notifications?.map((notification) => (
                        <div
                          key={notification._id}
                          className={`px-4 py-2  bg-gray-100 border-b`}
                        >
                          <p className="text-sm text-gray-800">
                            {notification.message}
                          </p>
                          <a
                            href={notification.actionURL}
                            className="text-blue-500 text-xs hover:underline"
                          >
                            Go to Task
                          </a>
                          <p className="text-xs text-gray-500 mt-1">
                            {new Date(notification.createdOn).toLocaleString()}
                          </p>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* <Link to="/feedback">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2">
              <FaCommentDots size={20} />
              Feedback
            </button>
          </Link> */}
          {isLoggedIn ? (
            <div className="relative mt-1">
              {profileImageUrl ? (
                <button
                  onClick={toggleDropdown}
                  className="bg-gray-500 text-orange-400 font-bold rounded-full w-12 h-12 flex items-center justify-center"
                >
                  <img
                    src={profileImageUrl}
                    alt="profile"
                    className="w-full h-full object-cover  rounded-full"
                  />
                </button>
              ) : (
                <button
                  onClick={toggleDropdown}
                  className="bg-gray-500 text-orange-400 font-bold py-2 px-4  rounded-full w-12 h-12 flex items-center justify-center"
                >
                  <FaUser size={20} />
                </button>
              )}

              {dropdownVisible && (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-50">
                  <Link
                    to="/profile"
                    onClick={handleMenuToggle}
                    className="block font-bold px-4 py-2 text-gray-600 hover:bg-gray-200"
                  >
                    Profile
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 font-bold  text-gray-600 hover:bg-gray-200"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <Link to="/register">
                <button className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded flex items-center gap-2 ml-2">
                  <FaUserPlus size={20} />
                  Register
                </button>
              </Link>
              <Link to="/login">
                <button className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded flex items-center gap-2">
                  <FaSignInAlt size={20} />
                  Login
                </button>
              </Link>
            </>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden mr-2">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/tasks">
              <button className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-2">
                <FaTasks size={20} />
                Find Tasks  <span className="text-xs text-red-600 mt-1">New</span>
              </button>
            </Link>
            <Link to="/find-worker" onClick={handleMenuToggle}>
              <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-2">
                <FaUserTie size={20} />
                Hire a Worker
              </button>
            </Link>

            <Link to="/find-work" onClick={handleMenuToggle}>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-1">
                <FaSearch size={20} />
                Find Work
              </button>
            </Link>

            {/* <Link to="/feedback" onClick={handleMenuToggle}>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-1">
                <FaCommentDots size={20} />
                Feedback
              </button>
            </Link> */}

            {isLoggedIn ? (
              <>
                <Link to="/profile" onClick={handleMenuToggle}>
                  <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2  px-4 rounded w-full flex items-center gap-2 mt-1">
                    <FaUserTie size={20} />
                    Profile
                  </button>
                </Link>
                <button
                  onClick={handleLogout}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-1"
                >
                  <FaSignOutAlt size={20} />
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/register" onClick={handleMenuToggle}>
                  <button className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-1">
                    <FaUserPlus size={20} />
                    Register
                  </button>
                </Link>
                <Link to="/login" onClick={handleMenuToggle}>
                  <button className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-1">
                    <FaSignInAlt size={20} />
                    Login
                  </button>
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
